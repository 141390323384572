<template>
  <div>
    <Form
      class="space-y-6"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      dispatch="user/resetPassword"
      @success="handleSucces"
    >
      <Field
        type="password"
        name="password"
        placeholder="Password"
        label="Password"
      />
      <Field
        type="password"
        name="confirmPassword"
        placeholder="Confirm Password"
        label="Confirm Password"
      />
      <div class="flex justify-between items-center">
        <Button>Reset Password</Button>
        <router-link to="login" class="font-semibold text-sm text-blue-500">
          Login
        </router-link>
      </div>
    </Form>
  </div>
</template>

<script>
import { object, string, ref } from 'yup';
import { useRoute } from 'vue-router';
import { useToast } from '@/helpers/composables';

export default {
  setup() {
    const route = useRoute();
    const toast = useToast();
    const {
      query: { id, token },
    } = route;

    const initialValues = {
      id,
      token,
    };

    const validationSchema = object().shape({
      password: string().min(8).required(),
      confirmPassword: string()
        .required()
        .oneOf([ref('password'), null], 'passwords do not match.'),
    });

    function handleSucces(message){
      password.value = null;
      confirmPassword.value = null;
      toast.show(message.message);
    } 


    return {
      initialValues,
      validationSchema,
      handleSucces
    };
  },
};
</script>
