<template>
  <div>
    <ResetPasswordForm />
  </div>
</template>

<script>
import ResetPasswordForm from '@/components/forms/ResetPasswordForm.vue';

export default {
  components: {
    ResetPasswordForm,
  },
};
</script>
